<template>
  <DataTable :options="options" />
</template>

<script>
import { FeatureMixin } from '@tutti/mixins';

export default {
  mixins: [FeatureMixin],

  data() {
    return {
      options: {
        export: true,
        headers: [
          { text: 'Name', value: 'name', tooltip: true, width: '50%' },
          { text: 'Space count', value: 'count.spaces' },
          { text: 'Location count', value: 'count.locations' },
        ],
        action: {
          hideView: true,
          hideEdit: true,
          showPreview: true,
          onPreview: item => window.open(`${this.artistURL}/search?${item.query}`, '_blank'),
        },
      },
    };
  },

  created() {
    this.$setFeatures({
      attributes: true,
      features: true,
    });

    this.options.filters = [
      {
        label: 'Attributes',
        items: this.attributes,
        itemText: 'name',
        itemValue: '_id',
        key: 'attributeIds',
      },
      {
        label: 'Features',
        items: this.features,
        itemText: 'name',
        itemValue: '_id',
        key: 'featureIds',
      },
      // {
      //   label: 'Activities',
      //   items: this.activities,
      //   itemText: 'name',
      //   itemValue: '_id',
      //   key: 'activities.attributeId',
      // },
      // {
      //   label: 'Space types',
      //   items: this.spaceTypes,
      //   itemText: 'name',
      //   itemValue: '_id',
      //   key: 'types.attributeId',
      // },
      // {
      //   label: 'Location types',
      //   items: this.locationTypes,
      //   itemText: 'name',
      //   itemValue: '_id',
      //   key: 'locationTypes.attributeId',
      // },
    ];
  },
};
</script>
